import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['placeholder']

  connect() {
    if (!this.placeholderTarget.value) {
      this.placeholderTarget.classList.add('empty')
    }
  }

  click(event) {
    const select = event.target
    select.addEventListener('change', () => {
      if (!!select.value) {
        select.classList.remove('empty')
      } else {
        select.classList.add('empty')
      }
    })
  }
}
