import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['select']
  connect() {
    this.selectTarget.value = document.location.pathname
  }

  redirect(event) {
    let current_location = event.target.value
    document.location = event.target.value
  }
}
