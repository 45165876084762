import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['container', 'pagination', 'wrapper']

  connect() {
    window.onresize = () => {
      let height = window.innerHeight - 80
      this.wrapperTarget.style.height = height + "px";
    }
    window.onresize()
    this.containerTarget.scrollTop = this.containerTarget.scrollHeight
    this.containerTarget.addEventListener('scroll', this.scroll.bind(this))
    if (this.containerTarget.scrollTop == 0) {
      if (this.paginationTarget.querySelector('a[rel="next"]') != null) {
        let url = this.paginationTarget.querySelector('a[rel="next"]').href
        this.loadMore(url)
      }
      else {
        this.containerTarget.insertAdjacentHTML('afterBegin', '<div class="text-center">Ikke flere beskeder</div>')
      }
    }
  }

  scroll(event) {
    if (event.target.scrollTop == 0) {
      if (this.paginationTarget.querySelector('a[rel="next"]') != null) {
        let url = this.paginationTarget.querySelector('a[rel="next"]').href
        this.loadMore(url)
      }
      else {
        if (!this.scrolledToEnd) {
          this.containerTarget.insertAdjacentHTML('afterBegin', '<div class="text-center">Ikke flere beskeder</div>')
          this.scrolledToEnd = true
        }
      }
    }
  }

  loadMore(url) {
    const tokenDom = document.querySelector('meta[name=csrf-token]')
    fetch(url, {
      type: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': tokenDom.content
      }
    }).then(response => response.json())
      .then(data => {
        let currentHeight = this.containerTarget.scrollHeight
        this.containerTarget.insertAdjacentHTML('afterBegin', data.entries)
        this.paginationTarget.innerHTML = data.pagination
        this.containerTarget.scrollTop = this.containerTarget.scrollHeight - currentHeight
      }
      )
  }

  scrollEnd() {
    setTimeout(() => {
      this.containerTarget.scrollTop = this.containerTarget.scrollHeight
    }, 50);
  }

}
