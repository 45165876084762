import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['input', 'container', 'submit_button']

  add_message(e){
    location.reload()
    this.formTarget.reset()
  }
}
