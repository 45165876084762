import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['changed', 'show']
  }

  trigger() {
    this.showTarget.classList.toggle('hidden')
    this.changedTarget.classList.toggle('bg-[#CDE4DD]')
  }
}
