import { Controller } from "@hotwired/stimulus"
import 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'


export default class extends Controller {
  static get targets() {
    return ['zipCode', 'street', 'city', 'kommune', 'map']
  }

  connect() {
    let address = this.zipCodeTarget.value + this.streetTarget.value + this.kommuneTarget.value + this.cityTarget.value
    if (address != '') {
      this.onChange() // only on first page load
    }
  }

  //triggerd by change stimulus event on address form
  onChange() {
    // simple debounce workaround
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.address().then((result) => { if (result.length > 0) { this.search(result) } })
    }, 100);
  }

  search(address) {
    if (this.map) {
      this.map.remove()
    }
    this.map = L.map(this.mapTarget).setView([address[0]['y'], address[0]['x']], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(this.map);
    L.marker({ lon: address[0]['x'], lat: address[0]['y'] }).bindPopup('Approximate location').addTo(this.map);
  }

  async address() {
    let address = [this.kommuneTarget.value, this.zipCodeTarget.value, this.cityTarget.value, this.streetTarget.value].join(' ')
    const provider = new OpenStreetMapProvider()
    const results = provider.search({ query: address })
    return results
  }
}
