import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { name: String, url: String }

  added(event) {
    if (event.target.value != '') {
      document.getElementById(this.nameValue + '_preview').classList.remove('hidden')
      document.getElementById(this.nameValue + '_name').innerText = event.target.value.split('\\')[2]
    }
  }

  delete(event) {
    document.getElementById(this.nameValue + '_input').value = ''
    document.getElementById(this.nameValue + '_preview').classList.add('hidden')
    console.log(event.params.id)
    fetch(this.urlValue, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
      },
      body: JSON.stringify({ 'id': event.params.id, 'name': this.nameValue }),
    })
  }
}
