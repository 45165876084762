import { Controller } from "@hotwired/stimulus"
import 'leaflet'

export default class extends Controller {
  static targets = ['map']
  static values = { location: Array }

  connect() {
    setTimeout(() => {
      this.addMap()
    }, 50);
  }

  addMap() {
    var map = L.map(this.mapTarget).setView([this.locationValue[0], this.locationValue[1]], 13);
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(map);
    L.marker({ lon: this.locationValue[1], lat: this.locationValue[0] }).addTo(map);
  }
}
