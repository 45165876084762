import { Controller } from "@hotwired/stimulus"
import 'leaflet'
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default class extends Controller {
  static targets = ['map', 'container', 'list']
  static values = { listings: Array }

  initializeMap() {
    if (this.initialized) {
      return
    }
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    let location = { 'lat': 56.170249, "lng": 10.3333283 }
    const map = L.map(this.mapTarget, {gestureHandling: true}).setView([location.lat, location.lng], 13);
    map.setZoom(7)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(map);
    if (this.listingsValue.length > 0) {
      this.listingsValue.forEach(element => {
        const photoImg = `<img src="${element.image}", style="max-width: 100%; max-height: 100%; border-radius: 5px;" class="aspect-auto m-auto"/>`;
        let description = element.description.length < 300 ? element.description : element.description.slice(0, 300) + "..."
        let bindUpContent = `
              <strong>${this.changeSigns(decodeURIComponent(element.title))}</strong>
              <p style="word-wrap: break-word;">
                ${this.changeSigns(decodeURIComponent(description))}
              </p>
              <div class='bg-gray-100 w-full h-full'>
                ${photoImg}
              </div>
              <br> 
              <div class='flex flex-row gap-2 content-center'>
                <div class='flex flex-row items-center gap-1'>
                  <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M20 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H20C21.1 18 22 17.1 22 16V2C22 0.9 21.1 0 20 0ZM20 16H2V2H12V6H20V16Z" fill="#6B7280"/>
                  </svg>
                  <div class='text-sm text-gray-500'>
                    ${element.total_area} m
                  </div>
                </div>
                <div class='flex flex-row items-center gap-2'>
                  <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 14.01V7H9V14.01H6L10 18L14 14.01H11ZM4 0L0 3.99H3V11H5V3.99H8L4 0ZM11 14.01V7H9V14.01H6L10 18L14 14.01H11ZM4 0L0 3.99H3V11H5V3.99H8L4 0Z" fill="#6B7280"/>
                  </svg>
                  <div class='text-sm text-gray-500'>
                  ${element.yield} %
                  </div>
                </div>
                <div class='flex flex-row items-center gap-2'>
                  <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M6 0L0 6V16H12V6L6 0ZM10 14H7V11H5V14H2V6.83L6 2.83L10 6.83V14ZM7 9H5V7H7V9ZM16 16V4.35L11.65 0H8.82L14 5.18V16H16ZM20 16V2.69L17.31 0H14.48L18 3.52V16H20Z" fill="#6B7280"/>
                  </svg>
                  <div class='text-sm text-gray-500'>
                  ${element.number_of_units}
                  </div>
                </div>
              </div>
              <div class='flex justify-end text-gray-800 mt-1'>
                <div>${parseInt(element.total_value).toLocaleString()} DKK</div>
              </div>`
        let bindUpContainer = document.createElement('div')
        bindUpContainer.classList.add("hover:cursor-pointer")
        bindUpContainer.innerHTML = bindUpContent;
        bindUpContainer.addEventListener('click', () => {this.onClick(element.path)})
        let marker = L.marker({ lon: element.lng, lat: element.lat }).addTo(map)
                      .bindPopup(bindUpContainer, {minWidth: 320});
      });
    }
    this.map = map
    this.mapTarget.scrollIntoView()
    this.initialized = true
  }

  changeSigns(e) {
    return e.replaceAll("+", " ")
  }

  show(e) {
    this.mapTarget.classList.toggle('hidden')
    e.target.classList.toggle('bg-[#CDE4DD]')
    this.listTarget.classList.toggle('hidden')
    this.listTarget.classList.toggle('lg:grid-cols-4')
    this.listTarget.classList.toggle('lg:grid-cols-2')
    this.containerTarget.classList.toggle('lg:h-screen')
    this.initializeMap()
  }

  onClick(url) {
    window.open(url)
  }
}
