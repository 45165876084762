import { Controller } from "@hotwired/stimulus"
import Dropzone from 'dropzone'
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ['input', 'submit', 'form', 'delete']
  static values = { url: String, fileTypes: String }
  dragSrcEl

  connect() {
    Sortable.create(document.getElementById('photos-container'), {
      animation: 150,
      onEnd: e => {
        fetch(this.urlValue + '/reorder', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-TOKEN': tokenDom.content
          },
          body: JSON.stringify({ 'new': e.newIndex + 1, 'old': e.oldIndex + 1 })
        })
      }
    })
    const tokenDom = document.querySelector('meta[name=csrf-token]')
    let myDropzone = new Dropzone(this.inputTarget, {
      url: this.urlValue,
      method: 'post',
      autoProcessQueue: true,
      uploadMultiple: true,
      acceptedFiles: JSON.parse(this.fileTypesValue).join(','),
      maxFilesize: 10,
      parallelUploads: 100,
      maxFiles: 100,
      clickable: document.getElementById('clickable'),
      disablePreviews: true,
      headers: {
        'X-CSRF-TOKEN': tokenDom.content
      }
    })
    myDropzone.on('success', (file, response) => {
      let photos = document.getElementById('photos-container')
      photos.innerHTML = ''
      let template = document.getElementById('file-template')
      photos.classList.add('photos-container')
      let container = document.getElementById('photos')
      response.forEach(function (value, i) {
        let t = template.cloneNode('true')
        t.querySelector('#delete').dataset.dropzoneIdParam = value[0].id
        t.classList.remove('hidden')
        t.querySelector('#image').src = value[1]
        photos.appendChild(t)
      })
      container.innerHTML = ''
      container.appendChild(photos)
      Sortable.create(photos, {
        delay: 1000,
        onEnd: e => {
          fetch(this.urlValue + '/reorder', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CSRF-TOKEN': tokenDom.content
            },
            body: JSON.stringify({ 'new': e.newIndex + 1, 'old': e.oldIndex + 1 })
          })
        }
      })
    })
  }

  delete(event) {
    console.log(this.urlValue)
    fetch(this.urlValue + '/' + event.params.id, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': document.querySelector('meta[name=csrf-token]').content
      },
    }).then(response => response)
      .then(result => (result.status == 200) ? this.removePhoto(event) : console.log(result.json()))
  }

  removePhoto(photo) {
    console.log(photo)
    photo.target.closest('#file-template').remove()
  }

}