import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "hiddenField"]
  

  connect() {
    this.fieldTarget.oninput = () => {
      this.typingTimer
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(() => { this.formatField(this.fieldTarget) }, 1000)
    }
  }

  formatField(field) {
    let preFormatted = field.value.replace(/[.,\s]/g, "")
    this.hiddenFieldTarget.value = preFormatted
    field.value = new Intl.NumberFormat('pl-PL').format(preFormatted)
  }
}
