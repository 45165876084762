import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static get targets() {
    return ['changed', 'show']
  }

  connect() {
    if (this.changedTarget.checked) {
      this.showTarget.classList.add('hidden')
    }
  }
  trigger() {
    this.showTarget.classList.toggle('hidden')
    if (this.changedTarget.checked) {
      this.confirmAction()
    }
  }
  confirmAction() {
    let customConfirmContainer = document.createElement('div')
    customConfirmContainer.classList.add('confirm-container')
    let screenCover = document.createElement('div')
    screenCover.classList.add('screen-cover')
    let customConfirmDialog = document.createElement('div')
    customConfirmDialog.classList.add('confirm')
    let title = document.createElement('div')
    title.innerText = "Mægler vurdering"
    title.classList.add('text-lg', 'font-medium')
    let description = document.createElement('div')
    description.innerText = 'Ejendomme med mere end 6 enheder, kræver mere arbejde at vurdere. Du bliver kontaktet af mægler med tilbud om vurdering.'
    description.classList.add('text-sm', 'text-gray-500')
    let button = document.createElement('button')
    button.innerText = 'Fortsæt'
    button.classList.add('primary-bg', 'text-white', 'rounded', 'h-10')
    button.onclick = () => { document.body.removeChild(customConfirmContainer) }


    customConfirmDialog.appendChild(title)
    customConfirmDialog.appendChild(description)
    customConfirmDialog.appendChild(button)
    customConfirmContainer.appendChild(customConfirmDialog)
    customConfirmContainer.appendChild(screenCover)
    document.body.appendChild(customConfirmContainer)
  }
}
