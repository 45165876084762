import { Controller } from "@hotwired/stimulus"
import Dropzone from 'dropzone'

export default class extends Controller {
  static targets = ['input', 'form', 'display']
  static values = { url: String, fileTypes: String }

  connect() {
    const tokenDom = document.querySelector('meta[name=csrf-token]')
    let myDropzone = new Dropzone(this.inputTarget, {
      url: this.urlValue,
      method: 'patch',
      autoProcessQueue: true,
      uploadMultiple: false,
      acceptedFiles: JSON.parse(this.fileTypesValue).join(','),
      clickable: document.getElementById('clickable'),
      disablePreviews: true,
      headers: {
        'X-CSRF-TOKEN': tokenDom.content
      }
    })
    myDropzone.on('success', (file, response) => {
      console.log(response['url'])
      let img = document.createElement('img')
      img.src = response['url']
      img.classList.add('rounded-full', 'h-24', 'w-24')
      this.displayTarget.replaceChildren(img)
    })
  }
}