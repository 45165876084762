import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['clickable']
  static values = { url: String, id: String, state: Boolean }

  click(event) {
    event.preventDefault()
    event.stopPropagation()
    const tokenDom = document.querySelector('meta[name=csrf-token]')
    if (!this.stateValue) {
      fetch(this.urlValue + `/${this.idValue}/favourites`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': tokenDom.content
        },
        body: JSON.stringify({ 'id': this.idValue })
      }).then(response => response.json())
        .then(data => this.changeIcon(data.message));
    }
    else {
      fetch(this.urlValue + `/${this.idValue}/favourites/remove`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': tokenDom.content
        },
        body: JSON.stringify({ 'id': this.idValue })
      }).then(response => response.json())
        .then(data => this.changeIcon(data.message));
    }
  }

  changeIcon(message) {
    let target = document.querySelector('[data-favourites-id-value="' + this.idValue + '"]')
    if (message == 'successfully added') {
      this.stateValue = !this.stateValue
      target.innerHTML = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 19.1752L8.55 17.8552C3.4 13.1852 0 10.1052 0 6.3252C0 3.2452 2.42 0.825195 5.5 0.825195C7.24 0.825195 8.91 1.6352 10 2.9152C11.09 1.6352 12.76 0.825195 14.5 0.825195C17.58 0.825195 20 3.2452 20 6.3252C20 10.1052 16.6 13.1852 11.45 17.8652L10 19.1752Z" fill="#EF4444"/></svg>'
    }
    else if (message == 'successfully removed') {
      this.stateValue = !this.stateValue
      target.innerHTML = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M14.5 0.825195C12.76 0.825195 11.09 1.6352 10 2.9152C8.91 1.6352 7.24 0.825195 5.5 0.825195C2.42 0.825195 0 3.2452 0 6.3252C0 10.1052 3.4 13.1852 8.55 17.8652L10 19.1752L11.45 17.8552C16.6 13.1852 20 10.1052 20 6.3252C20 3.2452 17.58 0.825195 14.5 0.825195ZM10.1 16.3752L10 16.4752L9.9 16.3752C5.14 12.0652 2 9.2152 2 6.3252C2 4.3252 3.5 2.8252 5.5 2.8252C7.04 2.8252 8.54 3.8152 9.07 5.1852H10.94C11.46 3.8152 12.96 2.8252 14.5 2.8252C16.5 2.8252 18 4.3252 18 6.3252C18 9.2152 14.86 12.0652 10.1 16.3752Z" fill="#1F2937"/></svg>'
    }
  }
}
