import { add, Controller } from "@hotwired/stimulus"
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';
import 'photoswipe/style.css';
import Splide from '@splidejs/splide'
import '@splidejs/splide'
import '@splidejs/splide/dist/css/splide.min.css'
import 'photoswipe/dist/photoswipe.css'

export default class extends Controller {
  static targets = ['gallery']

  connect() {
    this.addPhotoSwipe()
    this.addSplide()
  }

  addPhotoSwipe() {
    const lightbox = new PhotoSwipeLightbox({
      gallery: this.galleryTarget,
      children: 'a',
      showHideAnimationType: 'zoom',
      
      pswpModule: PhotoSwipe
    });

    lightbox.init();
  }

  addSplide() {
    var splide = new Splide(this.galleryTarget, {
    });

    splide.mount();
  }
}
