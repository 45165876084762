import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  initialize() {
    this.close = this.close.bind(this)
    setTimeout(() => {
      this.element.classList.add('fade')
      setTimeout(() => {
        this.close()
      }, 1000);
    }, 10000);
  }

  close() {
    this.element.remove()
  }
}