import { Controller } from "@hotwired/stimulus"
import 'leaflet'
import { OpenStreetMapProvider } from 'leaflet-geosearch'
import * as L from "leaflet";
import { GestureHandling } from "leaflet-gesture-handling";
import "leaflet-gesture-handling/dist/leaflet-gesture-handling.css";

export default class extends Controller {                 // This is a controller for buyer listings
  static targets = ['map', 'container', 'list']
  static values = { cities: Array }

  initializeMap() {
    if (this.initialized) {
      return
    }
    let location = { 'lat': 56.170249, "lng": 10.3333283 }
    L.Map.addInitHook("addHandler", "gestureHandling", GestureHandling);
    const map = L.map(this.mapTarget, {gestureHandling: true}).setView([location.lat, location.lng], 13);
    map.setZoom(7),

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      scrollWheelZoom: false,
      attribution: '&copy; <a href="https://openstreetmap.org/copyright">OpenStreetMap contributors</a>'
    }).addTo(map);
    if (Object.entries(this.citiesValue).length > 0) {
      Object.entries(this.citiesValue).forEach(entry => {
        this.add_popup(map, Object.keys(entry[1])[0],
          Object.values(entry[1])[0], 
          Object.values(entry[1])[1], 
          Object.values(entry[1])[2])
      });
    }
    this.map = map
    this.mapTarget.scrollIntoView()
    this.initialized = true
  }

  async add_popup(map, city, count, min, max){
    const provider = new OpenStreetMapProvider()
    const results = await provider.search({ query: city })
    let coordinates = results[0]
    if (coordinates){
      var marker = L.marker({ lon: coordinates['x'], lat: coordinates["y"] })
      .addTo(map)
      .bindPopup(
        `<div class='flex flex-col gap-1'>
          <div class='text-lg font-semibold text-center'> ${city.charAt(0).toUpperCase() + city.slice(1)}</div>
          <div>
            <div class='text-center'> <b>Number of offers:</b> ${count} </div>
          </div>
          <div>
            <div class= 'text-center'><b>From:</b> ${min} DKK</div> 
            <div class='text-center'><b>To:</b> ${max} DKK</div>
          </div>
        </div>`)

      marker.on('mouseover', function (e) {
        this.openPopup();
      });
      marker.on('mouseout', function (e) {
          this.closePopup();
      });
      marker.on('click', function (e) {
        window.location = ("/buyer_listings?q%5Bcity_or_zip_code_or_area_cont%5D=" + city);
      });
    }
  }

  show(e) {
    this.mapTarget.classList.toggle('hidden')
    e.target.classList.toggle('bg-[#CDE4DD]')
    this.listTarget.classList.toggle('hidden')
    this.listTarget.classList.toggle('lg:grid-cols-4')
    this.listTarget.classList.toggle('lg:grid-cols-2')
    this.containerTarget.classList.toggle('lg:h-screen')
    this.initializeMap()
  }

  onClick(url) {
    window.open(url)
  }
}
