import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  initialize(){
    let copy = document.getElementById('copy')
    let sharePopUp = document.getElementById('sharePopUp')
    copy.addEventListener("mouseenter", function(){
      sharePopUp.classList.remove("hidden")
      document.getElementById("succesfullyShared").classList.add("hidden")
    })
    copy.addEventListener("mouseleave", function(){
      sharePopUp.classList.add("hidden")
    })
  }

  click(){
    navigator.clipboard.writeText(window.location.href)
    document.getElementById('sharePopUp').classList.add("hidden")
    document.getElementById("succesfullyShared").classList.remove("hidden")
  }
}
