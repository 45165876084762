import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['modal', 'cover', 'close', 'form']

  connect(){
    this.coverTarget.addEventListener('wheel', (e) => {
      if(e.target == this.coverTarget){
        e.preventDefault()
      }
    })
    this.closeTarget.addEventListener('click', () => {
      this.toggleForm()
    })
    this.coverTarget.addEventListener('click', (e) => {
      if(e.target == this.coverTarget){
        this.toggleForm()
      }
    })
  }

  toggleForm(){
    this.modalTarget.classList.toggle('hidden')
  }
}
